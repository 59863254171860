import React, { FC, useEffect, useMemo } from 'react'
import {
    CleanSheetsWidgetProps,
    ConferenceTickerWidgetProps,
    ContestResultListProps,
    EventCalendarWidgetProps,
    GameplanWidgetProps,
    LivetickerWidgetProps,
    PlayerInfoProps,
    RankingOverviewWidgetProps,
    RankingWidgetProps,
    ResultWidgetProps,
    StandingsWidgetProps,
    TeamGameplanWidgetProps,
    TeamInfoProps,
    WidgetProps,
    WidgetType,
} from '@sport1/types/web'
import dynamic from 'next/dynamic'
import Configuration from '@sport1/sport1-components/build/widgets/config'
import { PixelType } from '@sport1/news-styleguide/Types'
import Card from '@/components/Card'
import Config from '@/utils/Config'
import { REWORKED_STANDINGS } from '@/components/Standings'

export const disabledWidgets = [WidgetType.SQUAD]

const Gameplan = dynamic(() => import('@/components/Widget/Gameplan'), {
    ssr: false,
})
const Result = dynamic(() => import('@/components/Widget/Result'), {
    ssr: false,
})
const RankingOverview = dynamic(() => import('@/components/Widget/RankingOverview'), {
    ssr: false,
})
const Standings = dynamic(() => import('@/components/Widget/Standings'), {
    ssr: false,
})
const Live = dynamic(() => import('@/components/Widget/Live'), {
    ssr: false,
})
const Liveticker = dynamic(() => import('@/components/Widget/Liveticker'), {
    ssr: false,
})
const TeamInfo = dynamic(() => import('@/components/Widget/TeamInfo'), {
    ssr: false,
})
const PlayerInfo = dynamic(() => import('@/components/Widget/PlayerInfo'), {
    ssr: false,
})
const DataTape = dynamic(() => import('@/components/Widget/DataTape'), {
    ssr: false,
})
const CleanSheets = dynamic(() => import('@/components/Widget/CleanSheets'), {
    ssr: false,
})
const TeamGameplan = dynamic(() => import('@/components/Widget/TeamGameplan'), {
    ssr: false,
})
const EventCalendar = dynamic(() => import('@/components/Widget/EventCalendar'), {
    ssr: false,
})
const ConferenceTicker = dynamic(() => import('@/components/Widget/ConferenceTicker'), {
    ssr: false,
})
const Ranking = dynamic(() => import('@/components/Widget/Ranking'), {
    ssr: false,
})
const ContextResults = dynamic(() => import('@/components/Widget/ContestResults'), {
    ssr: false,
})

type Props = {
    widget: WidgetProps
}

const Widget: FC<Props> = ({ widget }) => {
    const widgetComponent = useMemo(() => {
        const tag = widget.tags && widget.tags[0]
        switch (widget.widgetType) {
            case WidgetType.GAMEPLAN:
                return <Gameplan tag={tag} {...(widget.params as GameplanWidgetProps)} />
            case WidgetType.RESULT:
                return <Result tag={tag} {...(widget.params as ResultWidgetProps)} />
            case WidgetType.LIVE:
                return <Live />
            case WidgetType.LIVE_TICKER:
                return <Liveticker tag={tag} {...(widget.params as LivetickerWidgetProps)} />
            case WidgetType.PLAYER_INFO:
                return <PlayerInfo {...(widget.params as PlayerInfoProps)} />
            case WidgetType.RANKING_OVERVIEW:
                return <RankingOverview {...(widget.params as RankingOverviewWidgetProps)} />
            case WidgetType.STANDINGS:
                const params = widget.params as StandingsWidgetProps
                return !REWORKED_STANDINGS.includes(params?.sportIdentifier) ? (
                    <Standings tag={tag} {...params} />
                ) : null
            case WidgetType.CONTEST_RESULT_LIST:
                return <ContextResults {...(widget.params as ContestResultListProps)} />
            case WidgetType.TEAM_INFO:
                return <TeamInfo {...(widget.params as TeamInfoProps)} />
            case WidgetType.DATA_TAPE:
                return <DataTape />
            case WidgetType.CLEAN_SHEETS:
                return <CleanSheets {...(widget.params as CleanSheetsWidgetProps)} />
            case WidgetType.TEAM_GAMEPLAN:
                return <TeamGameplan {...(widget.params as TeamGameplanWidgetProps)} />
            case WidgetType.EVENT_CALENDAR:
                return <EventCalendar tag={tag} {...(widget.params as EventCalendarWidgetProps)} />
            case WidgetType.CONFERENCE_TICKER:
                return (
                    <ConferenceTicker
                        tag={tag}
                        {...(widget.params as ConferenceTickerWidgetProps)}
                    />
                )
            case WidgetType.RANKING:
                return <Ranking {...(widget.params as RankingWidgetProps)} />
            default:
                return null
        }
    }, [widget.params, widget.tags, widget.widgetType])

    useEffect(() => {
        Configuration.environment = Config.S1_COMPONENTS_ENVIRONMENT
    }, [])

    return widgetComponent ? (
        <Card
            minHeight={'100svh' as PixelType} // It has been typed like that because we would need to add new type from RE throught NS till WEB and APP projects to handle it properly
            backgroundColor="pearl"
            hasPadding
        >
            {widgetComponent}
        </Card>
    ) : null
}

export default Widget
